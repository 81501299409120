// Basic
import * as React from "react";
import { useState } from "react";
import Sample from "../../Assets/Sample.xlsx";
import { Bulkdatasubmit, storeimg } from "../../Services/utils";
// Components
//External func components
import { SnackbarProvider, enqueueSnackbar } from "notistack";

import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { fiel } from "./Fields";
// MUI
import { Box, Typography, Button, LinearProgress } from "@mui/material";

export default function MultySubmit() {
  const [isOpen, setIsOpen] = useState(false);
  const fields = fiel;
  const [files, setFiles] = useState([]);
  const [progresspercent, setProgresspercent] = useState(0);

  async function uploadimg(e) {
    const file = e.target.files[0];
    if (file) {
      setFiles(file);
      await storeimg(file, setProgresspercent);
    }
    e.target.value = null;
  }

  async function Datafromsheet(e) {
    if (e.invalidData.length >= 1) {
      enqueueSnackbar(
        "Error, Missing Mandatory Fields from the Spreadsheet. Please check",
        {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
            autoHideDuration: 6000,
          },
        }
      );
    } else {
      const bulksub = await Bulkdatasubmit(e.validData);
      if (bulksub === true) {
        enqueueSnackbar("Informations successfully updated", {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
            autoHideDuration: 6000,
          },
        });
      } else {
        enqueueSnackbar("Error, Please try again", {
          variant: "error",
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
            autoHideDuration: 6000,
          },
        });
      }
    }
  }
  return (
    <Box padding={5}>
      <SnackbarProvider maxSnack={3} />
      <Box>
        <Typography variant="h5" color="#c9af7f">
          Multy Upload Section
        </Typography>
        <Box
          sx={{
            padding: "20px",
            border: "1px solid #ccc",
            marginTop: "20px",
            borderRadius: "20px",
          }}
        >
          <Typography variant="h5">Step 1</Typography>
          <Typography variant="h6">Download our Template</Typography>

          <Button
            type="submit"
            variant="contained"
            sx={{ height: "50px", my: "20px", background: "#c9af7f" }}
          >
            <a href={Sample} download="Sample.xlsx">
              Download Here
            </a>
          </Button>
        </Box>

        <Box
          sx={{
            padding: "20px",
            border: "1px solid #ccc",
            marginTop: "20px",
            borderRadius: "20px",
          }}
        >
          <Typography variant="h5">Step 2</Typography>
          <Typography variant="h6">
            Import your data file, make sure it's in .xlsx, .xls or .csv format{" "}
          </Typography>
          <ReactSpreadsheetImport
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            onSubmit={Datafromsheet}
            fields={fields}
            rowHook={(data, addError) => {
              // Validation
              if (data.Surname === undefined) {
                data.Surname = "-";
                addError("Surname", {
                  message: "Automatically Autofilled",
                  level: "info",
                });
              }
              if (data.TranslationText1 === undefined) {
                data.TranslationText1 = "-";
                addError("TranslationText1", {
                  message: "Automatically Autofilled",
                  level: "info",
                });
              }
              if (data.TranslationText2 === undefined) {
                data.TranslationText2 = "-";
                addError("TranslationText2", {
                  message: "Automatically Autofilled",
                  level: "info",
                });
              }
              // Transformation
              return { ...data };
              // Sorry John
            }}
          />

          <Button
            sx={{ height: "50px", my: "20px", background: "#c9af7f" }}
            color="primary"
            variant="contained"
            component="span"
            onClick={() => setIsOpen(true)}
          >
            Import Excell File
          </Button>
        </Box>

        <Box
          sx={{
            padding: "20px",
            border: "1px solid #ccc",
            marginTop: "20px",
            borderRadius: "20px",
          }}
        >
          <Typography variant="h5">Step 3</Typography>
          <Typography variant="h6">
            Import your media file, make sure it's in .zip format and all images
            name are matching the submited data above
          </Typography>
          <Typography variant="h6" fontSize={"15px"}>
            If the file is too large, try uploading it in multiple parts
          </Typography>
          <label htmlFor="upload-photo">
            <input
              style={{ display: "none" }}
              id="upload-photo"
              name="upload-photo"
              type="file"
              onChange={uploadimg}
            />

            <Button
              sx={{ height: "50px", my: "20px", background: "#c9af7f" }}
              color="primary"
              variant="contained"
              component="span"
            >
              Select File
            </Button>
          </label>

          <Box mt="20px">
            <Typography
              variant="caption"
              component="div"
              color="text.secondary"
            >
              {`${progresspercent}%`}
            </Typography>
            <LinearProgress variant="determinate" value={progresspercent} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
